AFRAME.registerComponent("sunbeams", {
  schema: {
    rayCount: { type: "int", default: 16 }, // Number of rays
  },

  init: function () {
    this.createSunbeams();
  },

  update: function () {
    // Update rays based on component data changes
    const parentObject = this.el.object3D;
    parentObject.children.forEach((child) => {
      if (child.type === "Mesh") {
        parentObject.remove(child);
      }
    });
    this.createSunbeams();
  },

  createSunbeams: function () {
    const parentObject = this.el.object3D; // Get the parent object

    // Create rays
    for (let i = 0; i < this.data.rayCount; i++) {
      const height = 0.5;
      const rayGeometry = new THREE.ConeGeometry(height, 5, 32);
      rayGeometry.rotateX(Math.PI);
      // shader seen here: http://stemkoski.github.io/Three.js/Shader-Glow.html
      const vertexShader = `
uniform vec3 viewVector;
uniform float c;
uniform float p;
varying float intensity;
void main() 
{
    vec3 vNormal = normalize( normalMatrix * normal );
	vec3 vNormel = normalize( normalMatrix * viewVector );
	intensity = pow( c - dot(vNormal, vNormel), p );
	
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
          `;
      const fragmentShader = `
uniform vec3 glowColor;
varying float intensity;
void main() 
{
	vec3 glow = glowColor * intensity;
    gl_FragColor = vec4( glow, 0.2 );
}
          `;

      const rayMaterial = new THREE.ShaderMaterial({
        uniforms: {
          c: { type: "f", value: 1.0 },
          p: { type: "f", value: 2.4 },
          glowColor: { type: "c", value: new THREE.Color(0xffff00) },
          viewVector: { type: "v3", value: parentObject.position },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        side: THREE.FrontSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
        opacity: 0.1,
      });

      const rayMesh = new THREE.Mesh(rayGeometry, rayMaterial);

      const posZ = (i % 3) + 2 + Math.random() * 3;
      const posY = -2.7;
      const posX = (i / 1.5) - 1.5;
      rayMesh.position.set(posX, posY, posZ);
      rayMesh.scale.set(0.1, 0.9, 0.2);
      rayMesh.lookAt(parentObject.position);
      rayMesh.rotateX((posX + 3)/10);
      parentObject.add(rayMesh); // Add rays to the parent object
    }
  },
});
